"use client"

import { useRef, useState } from "react"
import { CheckIcon, CopyIcon } from "lucide-react"

interface CopyBoxProps extends React.PropsWithChildren {}

export function CopyBox({ children }: CopyBoxProps) {
  const [showCheckIcon, setShowCheckIcon] = useState(false)
  const textRef = useRef<HTMLDivElement>(null)

  const handleCopy = () => {
    if (textRef.current) {
      const range = document.createRange()
      range.selectNode(textRef.current)
      window.getSelection()?.removeAllRanges()
      window.getSelection()?.addRange(range)
      window.document.execCommand("copy")
      window.getSelection()?.removeAllRanges()
      setShowCheckIcon(true)
      setTimeout(() => {
        setShowCheckIcon(false)
      }, 2000)
    }
  }

  return (
    <div className="relative flex w-full items-start self-stretch rounded-lg border border-primary bg-secondary p-4">
      <div ref={textRef} className="flex-1 overflow-hidden whitespace-pre-wrap break-words text-md text-primary">
        {children}
      </div>
      <div className="ml-2">
        {showCheckIcon ? (
          <CheckIcon className="cursor-pointer text-fg-secondary" />
        ) : (
          <CopyIcon className="cursor-pointer text-fg-secondary" onClick={handleCopy} />
        )}
      </div>
    </div>
  )
}
