"use client"

import Link from "next/link"
import { MailIcon, XIcon } from "lucide-react"
import { useMediaQuery } from "usehooks-ts"

import { Url } from "@/lib/constants"
import { formatNumber } from "@/lib/format"
import { useTranslation } from "@/providers/translation-provider"

import { Button } from "@/components/ui/button"
import { Dialog, DialogClose, DialogContent, DialogHeader, DialogTrigger } from "@/components/ui/dialog"
import { Drawer, DrawerClose, DrawerContent, DrawerHeader, DrawerTrigger } from "@/components/ui/drawer"
import { Icons } from "@/components/icons"
import { CopyBox } from "@/modules/user/components/copy-box"

interface LineOAContentProps {
  userEmail: string
  brandName: string
  brandSlug: string
  courseName: string
  courseSlug: string
  subTitle: string
  withProblemDescription?: boolean
}

export function LineOAContent({
  userEmail,
  brandName,
  brandSlug,
  courseName,
  courseSlug,
  subTitle,
  withProblemDescription = false,
}: LineOAContentProps) {
  const { t } = useTranslation()

  return (
    <div className="flex flex-col p-4 pt-0 lg:p-6 lg:pt-0">
      <span className="mb-5 text-sm text-fg-secondary">
        กรุณา<strong>คัดลอก</strong>ข้อความ และติดต่อฝ่ายสนับสนุนผ่านช่องทางด้านล่าง
      </span>
      <CopyBox>
        หัวข้อ : {subTitle}
        <br />
        บัญชีผู้ใช้ : {userEmail}
        <br />
        ชื่อแบรนด์ : {brandName}
        <br />
        Slug แบรนด์ : {brandSlug}
        <br />
        ชื่อ{t.class} : {courseName}
        <br />
        Slug {t.class} : {courseSlug}
        <br />
        {withProblemDescription && <span>รายละเอียดปัญหา: (กรุณาระบุ)</span>}
      </CopyBox>
      <Button variant="secondary" className="mt-6 h-10 w-full" size="md" asChild>
        <Link href={Url.LineOA} target="_blank" rel="noopener noreferrer">
          <Icons.Line className="size-5" />
          <span className="">@Playboard</span>
        </Link>
      </Button>
      <Button variant="secondary" className="mt-4 h-10 w-full" size="md" asChild>
        <Link href="mailto:support@playboard.cloud">
          <MailIcon className="size-5" />
          <span>support@playboard.cloud</span>
        </Link>
      </Button>
    </div>
  )
}

interface LineOAModalProps extends React.PropsWithChildren {
  brandName: string
  brandSlug: string
  userEmail: string
  courseName: string
  courseSlug: string
  title?: string
  subTitle?: string
  withProblemDescription?: boolean
}

export function LineOAModal({
  children,
  brandName,
  brandSlug,
  userEmail,
  courseName,
  courseSlug,
  title = `รับ ${formatNumber(2500)} เครดิตฟรี!`,
  subTitle = `ต้องการรับเครดิตทดลองใช้เพิ่ม ${formatNumber(2500)} เครดิต`,
  withProblemDescription = false,
}: LineOAModalProps) {
  const isDesktop = useMediaQuery("(min-width: 1024px)")

  if (isDesktop)
    return (
      <Dialog>
        <DialogTrigger className="flex w-full flex-col" asChild>
          {children}
        </DialogTrigger>
        <DialogContent className="relative rounded-xl p-0">
          <DialogHeader className="flex flex-row justify-between px-6 pb-0 pt-6">
            <span className="text-lg font-semibold">{title}</span>
            <DialogClose className=" outline-none">
              <XIcon size={24} className="text-fg-quinary" />
            </DialogClose>
          </DialogHeader>
          <LineOAContent
            brandName={brandName}
            brandSlug={brandSlug}
            userEmail={userEmail}
            courseName={courseName}
            courseSlug={courseSlug}
            subTitle={subTitle}
            withProblemDescription={withProblemDescription}
          />
        </DialogContent>
      </Dialog>
    )

  return (
    <Drawer>
      <DrawerTrigger className="flex w-full flex-col" asChild>
        {children}
      </DrawerTrigger>
      <DrawerContent className="  p-0">
        <DrawerHeader className=" flex justify-between px-4 pb-0 pt-4">
          <span className="text-start text-lg font-semibold">{title}</span>
          <DrawerClose className="  outline-none">
            <XIcon size={24} className="text-fg-quinary" />
          </DrawerClose>
        </DrawerHeader>
        <LineOAContent
          brandName={brandName}
          brandSlug={brandSlug}
          userEmail={userEmail}
          courseName={courseName}
          courseSlug={courseSlug}
          subTitle={subTitle}
          withProblemDescription={withProblemDescription}
        />
      </DrawerContent>
    </Drawer>
  )
}
