export type CourseType = "default" | "ติวเตอร์ออนไลน์" | "อีเวนต์" | "ลงทุน / พัฒนาทักษะ" | "อื่น ๆ หรือไม่ระบุ"

export interface CourseTerms {
  instructor: string
  class: string
  content: string
}
export const courseTerms: Record<CourseType, CourseTerms> = {
  default: {
    instructor: "ผู้สอน",
    class: "คลาส",
    content: "เนื้อหาในคลาส",
  },
  ติวเตอร์ออนไลน์: {
    instructor: "ผู้สอน",
    class: "คอร์ส",
    content: "เนื้อหาในคอร์ส",
  },
  อีเวนต์: {
    instructor: "Speaker",
    class: "อีเวนต์",
    content: "Sessions",
  },
  "ลงทุน / พัฒนาทักษะ": {
    instructor: "ผู้บรรยาย",
    class: "หลักสูตร",
    content: "เนื้อหาในหลักสูตร",
  },
  "อื่น ๆ หรือไม่ระบุ": {
    instructor: "ผู้สอน",
    class: "คลาส",
    content: "เนื้อหาในคลาส",
  },
}
