"use client"

import { createContext, useContext, type ReactNode } from "react"

import { courseTerms, type CourseTerms, type CourseType } from "@/lib/course-terms"

interface TranslationContextType {
  t: CourseTerms
}

const TranslationContext = createContext<TranslationContextType | undefined>(undefined)

interface TranslationProviderProps {
  courseType: string
  children: ReactNode
}

export function TranslationProvider({ courseType, children }: TranslationProviderProps) {
  const t = courseTerms[courseType as CourseType] || courseTerms.default

  return <TranslationContext.Provider value={{ t }}>{children}</TranslationContext.Provider>
}

export function useTranslation() {
  const context = useContext(TranslationContext)
  return context || { t: courseTerms.default }
}
